<template>
  <div class="col main_users_wrapping_container">
    <!-- Start Design For Print -->
    <div class="the_main_container_for_print_all_pdf bg-white">
      <div
        class="main_container"
        v-for="user in institutionsUsers"
        :key="user.id"
      >
        <!-- // Start User Details Large Screens  -->
        <div style="width: 100% " class="my-4 main_use_ur_pattern_container_lg">
          <card class="">
            <div class="main_auth_user_info">
              <div>
                <span class="d-block mb-2 n_name">{{
                  `${user.first_name} ${user.last_name}`
                }}</span>
                <span class="d-block n_email"> {{ user.email }}</span>
              </div>
              <div class="classification_classification">
                <span
                  class="classification"
                  v-if="user.classification == 'Bridge'"
                  >{{ localization("Bridge Learner") }}</span
                >
                <span
                  class="classification"
                  v-if="user.classification == 'Dynamic'"
                  >{{ localization("Dynamic Learner") }}</span
                >
                <span
                  class="classification"
                  v-if="user.classification == 'Strong-Willed Learner'"
                  >{{ localization("Strong-Willed Learner") }}</span
                >
              </div>
            </div>
            <div class="which_user_type mt-2 mb-3">
              <div class="nn">
                <div class="another_content">
                  <span
                    class="tit text-uppercase"
                    v-if="user.classification == 'Bridge'"
                    >{{ localization("Bridge Learner") }}
                    {{ localization("Definition") }}
                  </span>
                  <span
                    class="tit text-uppercase"
                    v-if="user.classification == 'Dynamic'"
                    >{{ localization("Dynamic Learner") }}
                    {{ localization("Definition") }}</span
                  >
                  <span
                    class="tit text-uppercase"
                    v-if="user.classification == 'Strong-Willed Learner'"
                    >{{ localization("Strong-Willed Learner") }}
                    {{ localization("Definition") }}</span
                  >
                  <!-- Description Text  -->

                  <span
                    class="tex d-block"
                    v-if="user.classification == 'Bridge'"
                  >
                    {{ localization("The Bridge Learner uses all Patterns at a Use as Needed level. Your Patterns serve a helpful role by leading from the middle. Bridge learners are team catalysts. The Bridge learner can close the gap of understanding between learners who have Use First and Avoid Patterns.") }}
                  </span>
                  <span
                    class="tex d-block"
                    v-if="user.classification == 'Dynamic'"
                  >
                    {{ localization("The Dynamic Learner uses at least one Pattern at the Use First level, while the remaining ones are any combination of another Use First, Use as Needed, or Avoid. When moving from using one Pattern to another you can actually sense that a shift in your thoughts, feelings and actions is occurring particularly when you move from your Use First Patterns to those you Avoid.") }}
                  </span>
                  <span
                    class="tex d-block"
                    v-if="user.classification == 'Strong-Willed Learner'"
                  >
                    {{ localization("The Strong-Willed Learner uses three or more Patterns at the Use First level. Strong-Willed learners seek opportunities to lead rather than be led. Your Patterns would have you prefer to control the plan, the ideas, the talks, the decisions, the processes, and the outcomes and do it all yourself. Understanding how your Patterns affect those around you is particularly important when you are a Strong-Willed Learner.") }}
                  </span>
                </div>
                <!-- Dynamic -->
                <!-- Strong-Willed Learner -->
              </div>
            </div>
            <!-- Part Three Cards -->
            <div class="row my-4  main-card-print print-page-break">
              <div
                class="col-sm-12 px-0 px-lg-3 d-flex align-items-center the_patterns_with_new_theme"
              >
                <!-- Start Sequence  -->
                <div style="min-width: 25%;">
                  <card
                    :height="`${user.sequence * 2.5 + 120}px`"
                    style="
                      background-color: #39c;
                      border-radius: 0;
                      width: 100%;
                      max-height: 230px;
                      min-height: 120px;
                    "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->
                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 2rem"
                      >
                        {{ localization("Sequence") }}
                      </p>
                      <div
                        class="
                        d-flex
                        align-items-center align-items-xxl-start
                        justify-content-center justify-content-lg-around
                      "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("Sequence shortcut") }}
                        </p>
                        <!-- Score Number  -->

                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize: (user.sequence / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{ user.sequence != null ? user.sequence : 0 }}
                        </p>
                      </div>
                    </div>
                    <div class="new_theme_pattern_content">
                      <div class="main_content_container with_sequence">
                        <span class="d-block text-uppercase ti">{{
                          localization("SEQUENTIAL LEARNING PATTERNS")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Organizes life")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Makes Lists")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Lives by schedules")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Makes extensive plans")
                        }}</span>
                      </div>
                    </div>
                  </card>
                </div>
                <!-- End Sequence  -->
                <!-- Start Precision   -->
                <div style="min-width: 25%;">
                  <card
                    :height="`${user.precision * 2.5 + 120}px`"
                    style="
                  border-radius: 0;

                  min-width: 25%;
                  background-color: #3c3;
                  max-height: 230px;
                  min-height: 120px;
            "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->

                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 2rem"
                      >
                        {{ localization("precision") }}
                      </p>

                      <div
                        class="
                        d-flex
                        align-items-center align-items-xxl-start
                        justify-content-center justify-content-lg-around
                      "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("precision shortcut") }}
                        </p>

                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize: (user.precision / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{ user.precision != null ? user.precision : 0 }}
                        </p>
                      </div>
                    </div>
                    <div class="new_theme_pattern_content">
                      <div class="main_content_container with_precision">
                        <span class="d-block text-uppercase ti">{{
                          localization("PRECISION LEARNING PATTERNS")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Relishes information")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Seeks facts")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Must be right")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Provides detailed responses")
                        }}</span>
                      </div>
                    </div>
                  </card>
                </div>
                <!-- End Precision   -->
                <!-- Start Technical Reasoning  -->
                <div style="min-width: 25%;">
                  <!-- border: 1px solid #ccc; -->
                  <card
                    :height="`${user.technical_reasoning * 2.5 + 120}px`"
                    style="
                border-radius: 0;

                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->
                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 1.6rem"
                      >
                        {{ localization("Technical Reasoning") }}
                      </p>
                      <div
                        class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around

                  "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("Technical Reasoning shortcut") }}
                        </p>

                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize:
                              (user.technical_reasoning / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{
                            user.technical_reasoning != null
                              ? user.technical_reasoning
                              : 0
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="new_theme_pattern_content">
                      <div
                        class="main_content_container with_technical_reasoning"
                      >
                        <span class="d-block text-uppercase ti">{{
                          localization("TECHNICAL LEARNING PATTERNS")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Solves problems")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Uses few words")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Looks for relevance and practicality")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Works alone")
                        }}</span>
                      </div>
                    </div>
                  </card>
                </div>
                <!-- End Technical Reasoning  -->
                <!--Start Confluence  -->
                <div style="min-width: 25%;">
                  <card
                    :height="`${user.confluence * 2.5 + 120}px`"
                    style="
                    border-radius: 0;

                    min-width: 25%;
                    background-color: #c36;
                    max-height: 230px;
                    min-height: 120px;
                  "
                    class="my-auto score"
                  >
                    <div class="d-flex flex-column my-auto">
                      <!-- score title text -->
                      <!-- Medium & larger screens text   -->
                      <p
                        class="mt-1 text-center text-white mb-0"
                        style="font-size: 2rem"
                      >
                        {{ localization("Confluence") }}
                      </p>

                      <div
                        class="
                        d-flex
                        align-items-center align-items-xxl-start
                        justify-content-center justify-content-lg-around
                      "
                      >
                        <!-- First Letter  -->
                        <p class="letter mb-0 text-white">
                          {{ localization("Confluence shortcut") }}
                        </p>
                        <!-- Score Number  -->
                        <!-- Large Screens  -->
                        <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                        <p
                          class="number mb-0 text-white"
                          :style="{
                            fontSize: (user.confluence / 28) * 25 + 65 + 'px'
                          }"
                        >
                          {{ user.confluence != null ? user.confluence : 0 }}
                        </p>
                      </div>
                    </div>
                    <div class="new_theme_pattern_content">
                      <div class="main_content_container with_confluence">
                        <span class="d-block text-uppercase ti">{{
                          localization("CONFLUENCE LEARNING PATTERNS")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Takes risks")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Sees the big picture")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Embraces innovation")
                        }}</span>
                        <span class="d-block te">{{
                          localization("Initiates change")
                        }}</span>
                      </div>
                    </div>
                  </card>
                </div>
                <!--End Confluence  -->
              </div>
            </div>
          </card>
        </div>

        <!-- // End User Details Large Screens  -->
        <!-- Start Two Strategy Cards   -->
        <div class="mt-4 mb-4 ">
          <el-collapse class="mt-5" v-model="panel" multiple>
            <div class="child" v-for="(item, i) in user.attributes" :key="i">
              <el-collapse-item
                :name="`${i}`"
                multiple
                :class="{
                  'print-page-break': i == 'Precision',
                  'print-page-break_2': i == 'Confluence'
                }"
              >
                <!-- //! start  collapse title -->
                <template #title>
                  <div
                    class="main_collapse_title_wrapper "
                    :class="{ is_rtl: isRtl() }"
                    style="width: 100%s"
                  >
                    <div>
                      <h4 class="every_collapse_title">
                        <i
                          :class="`fa fa-star mr-1 ${i}`"
                          :style="`color: ${getProgressColor(i)}`"
                        ></i>
                        {{ localization(i) }}
                      </h4>
                    </div>
                  </div>
                </template>
                <!-- //! end  collapse title -->
                <div>
                  <el-collapse class="pt-3 pl-lg-4">
                    <span
                      class="span_attr_type d-block"
                      :class="{ 'text-right': isRtl() }"
                      >{{ localization(item[0].type) }}</span
                    >
                    <l-alert
                      :style="
                        `opacity:0.8;background-color : ${getProgressColor(i)}`
                      "
                      class="mb3 mt-2 alert_for_collapse"
                      :class="{ 'text-right': isRtl() }"
                    >
                      <span>
                        <b> {{ localization(item[0].type) }} - </b>
                        {{ getHint(i, item[0].type) }}
                      </span>
                    </l-alert>
                    <!-- v-model="item2.selected_attributes" -->

                    <div
                      class="child text_to_speech_container my-2"
                      v-for="(item2, i) in item"
                      :key="i"
                      :class="{ 'flex-row-reverse': isRtl() }"
                    >
                      <el-checkbox-group
                        v-if="item2"
                        :value="
                          user.selected_attributes != null
                            ? user.selected_attributes
                            : []
                        "
                      >
                        <el-checkbox
                          :label="item2.id"
                          :class="{
                            'd-none': item2.title < 1,
                            'flex-row-reverse': isRtl(),
                            'text-right': isRtl()
                          }"
                          class="mb-2"
                        >
                          {{ item2.title }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </el-collapse>
                </div>
              </el-collapse-item>
            </div>
          </el-collapse>
        </div>
        <!-- End Two Strategy Cards   -->
      </div>
    </div>
    <!-- End Design For Print -->

    <CustomTable
      class="hidden-sm-only hidden-xs-only main_big_screen_wrapper_users hide_me_printable"
      :count="$store.getters['user/totalUsers']"
      :DataItems="$store.getters['user/users']"
      :filter="filter"
      @Refresh="Refresh"
      @reset="filter = {}"
      @export="Export"
      :title="'teams'"
      import_url="users/import"
      :loading="$store.getters['user/usersLoad']"
      @toggleModal="ToggleTaskModal('add')"
      viewName="Users"
      admin_permission="admin_users"
      edit_permission="add_tasks"
      hidden_export_import
    >
      <template #filter>
        <el-row :gutter="10">
          <el-col :md="8" v-if="!hasPermission('manage_institutions')"
            ><el-select
              v-model="filter.organization_id"
              :placeholder="`${localization('Institution')}`"
              clearable
              filterable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(organization, i) in $store.getters['organization/orgs']"
                :key="i"
                :label="organization.name"
                :value="organization.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8"
            ><el-select
              v-model="filter.age_group_id"
              :placeholder="`${localization('Age')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(age, i) in ageList"
                :key="i"
                :label="age.label"
                :value="age.value"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8" v-if="!hasPermission('manage_institutions')"
            ><el-select
              v-model="filter.type"
              :placeholder="`${localization('Type')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(type, i) in types"
                :key="i"
                :label="type"
                :value="type"
              ></el-option> </el-select
          ></el-col>

          <el-col :md="8"
            ><el-select
              v-model="filter.category"
              :placeholder="`${localization('Category')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="cat in $store.getters['user/categories']"
                :key="cat.id"
                :label="cat.category"
                :value="cat.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for lci -->
          <el-col :md="8"
            ><el-select
              v-model="filter.lci"
              placeholder="LCI"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in lciFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for plp -->
          <el-col :md="8"
            ><el-select
              v-model="filter.selected_attributes"
              placeholder="PLP"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in plpFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for join team  -->
          <el-col :md="8"
            ><el-select
              v-model="filter.team_member"
              placeholder="Teams"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in joinTeamsFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for decode tasks -->
          <el-col :md="8"
            ><el-select
              v-model="filter.tasks"
              placeholder="Decoded School Tasks"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in decodeTasksFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for decode work tasks -->
          <el-col :md="8"
            ><el-select
              v-model="filter.work_tasks"
              placeholder="Decoded Work Tasks"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in decodeWorkTasksFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for decode career tasks -->
          <el-col :md="8"
            ><el-select
              v-model="filter.career_tasks"
              placeholder="Decoded Career Tasks"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in decodeCareerTasksFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
        </el-row>
      </template>
      <template #columns>
        <el-table-column
          prop="first_name"
          sortable
          :label="`${localization('First Name')}`"
          min-width="110"
        >
        </el-table-column>
        <el-table-column
          prop="last_name"
          sortable
          :label="`${localization('Last Name')}`"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          :label="`${localization('Email')}`"
          min-width="130"
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              {{ scope.row.email }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="category"
          :label="`${localization('Category')}`"
          min-width="90"
          sortable
        >
          <template slot-scope="scope">
            <div
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: normal;
                word-break: break-word;
                width: 100%;
              "
            >
              <div v-for="(item, i) in scope.row.category" :key="i">
                {{ item }}
              </div>
              <!-- {{ scope.row.category != null ? scope.row.category : "__" }} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="hasPermission('all_users')"
          prop="country"
          sortable
          :label="`${localization('Location')}`"
          min-width="90"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.city }} {{ scope.row.city ? "," : "" }}
              {{ scope.row.state }} {{ scope.row.state ? "," : "" }}
              {{ scope.row.country }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="age_group_id"
          sortable
          :label="`${localization('Age')}`"
          width="80"
        >
          <template slot-scope="scope">
            {{ getAgeGroup(scope.row.age_group_id) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="hasPermission('all_users')"
          prop="organization_name"
          sortable
          :label="`${localization('Organization')}`"
          width="140"
        ></el-table-column>
        <el-table-column
          v-if="hasPermission('all_users')"
          prop="type"
          :label="`${localization('Type')}`"
          sortable
          min-width="80"
        >
        </el-table-column>
        <el-table-column prop="family" label="Family" sortable min-width="80">
          <template slot-scope="scope">
            {{ scope.row.family == 1 ? `Family` : "__" }}
          </template>
        </el-table-column>
        <el-table-column
          :label="`${localization('Score')}`"
          min-width="150"
          v-if="!hasPermission('all_users') && hasPermission('access_users')"
        >
          <template slot-scope="scope">
            <span
              class="d-inline-block font-weight-bold h5 m-1 p-2 text-white"
              style="background-color: #39c"
              >{{ scope.row.sequence }}</span
            >
            <span
              class="d-inline-block font-weight-bold h5 m-1 p-2 text-white"
              style="background-color: #3c3"
              >{{ scope.row.precision }}</span
            >
            <span
              class="d-inline-block font-weight-bold h5 m-1 p-2 text-white"
              style="background-color: #f93"
              >{{ scope.row.technical_reasoning }}</span
            >
            <span
              class="d-inline-block font-weight-bold h5 m-1 p-2 text-white"
              style="background-color: #c36"
              >{{ scope.row.confluence }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="hasPermission('all_users')"
          prop="exp_time"
          sortable
          :label="`${localization('Expiration')}`"
          width="100"
        >
          <template slot-scope="scope">
            {{
              scope.row.exp_time
                ? toLocalDatetime(scope.row.exp_time, "en")
                : "_"
            }}
          </template>
        </el-table-column>

        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="td-actions">
              <!-- View Profile v-if="hasPermission('show_users')" -->
              <router-link
                v-if="hasPermission('manage_institutions') || hasPermission('show_users')"
                :to="`/new-theme/profile/${scope.row.id}`"
                v-tooltip.top-center="
                  `${localization('View profiley')}`
                "
                class="btn btn-info btn-link btn-xs"
              >
                <img
                  src="@/assets/new-theme/eye.png"
                  alt="eye-active"
                />
              </router-link>
              <!-- <router-link
                v-if="
                  hasPermission('manage_institutions') ||
                    hasPermission('show_users')
                "
                v-tooltip.top-center="`${localization('View profile')}`"
                class="btn btn-info btn-link btn-xs"
                :to="`/new-theme/profile/${scope.row.id}`"
                target="_blank"
              >
                <i class="fa fa-user" style="font-size: 17px"></i>
              </router-link> -->
              <!-- Delete or Reactivate User  -->
              <!-- v-if="hasPermission('edit_users')" -- just admin -->
              <a
                v-if="!hasPermission('manage_institutions')"
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete user')}`
                    : `${localization('Active user')}`
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
              <!-- //// Admin Institution --- just for him -->
              <a
                v-if="hasPermission('manage_institutions')"
                v-tooltip.top-center="`Remove user from institution`"
                @click="removeUserFromInstitutions(scope.row.id)"
                :class="
                  scope.row.removed == 0
                    ? `btn btn-danger btn-link btn-xs`
                    : `btn btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>
      
    </CustomTable>
    <CustomTable
      class="hidden-md-only hidden-lg-only hidden-xl-only main_mobile_wrapper_users hide_me_printable"
      :count="$store.getters['user/totalUsers']"
      :DataItems="$store.getters['user/users']"
      :filter="filter"
      @Refresh="Refresh"
      @reset="filter = {}"
      @export="Export"
      :title="'teams'"
      import_url="users/import"
      :loading="$store.getters['user/usersLoad']"
      @toggleModal="ToggleTaskModal('add')"
      viewName="Users"
      admin_permission="admin_users"
      edit_permission="add_tasks"
      hidden_export_import
    >
      <template #filter>
        <el-row :gutter="10">
          <el-col :md="8" v-if="!hasPermission('manage_institutions')"
            ><el-select
              v-model="filter.organization_id"
              :placeholder="`${localization('Institution')}`"
              clearable
              filterable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(organization, i) in $store.getters['organization/orgs']"
                :key="i"
                :label="organization.name"
                :value="organization.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8"
            ><el-select
              v-model="filter.age_group_id"
              :placeholder="`${localization('Age')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(age, i) in ageList"
                :key="i"
                :label="age.label"
                :value="age.value"
              ></el-option> </el-select
          ></el-col>
          <el-col :md="8" v-if="!hasPermission('manage_institutions')"
            ><el-select
              v-model="filter.type"
              :placeholder="`${localization('Type')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(type, i) in types"
                :key="i"
                :label="type"
                :value="type"
              ></el-option> </el-select
          ></el-col>

          <el-col :md="8"
            ><el-select
              v-model="filter.category"
              :placeholder="`${localization('Category')}`"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="cat in $store.getters['user/categories']"
                :key="cat.id"
                :label="cat.category"
                :value="cat.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for lci -->
          <el-col :md="8"
            ><el-select
              v-model="filter.lci"
              placeholder="LCI"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in lciFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for plp -->
          <el-col :md="8"
            ><el-select
              v-model="filter.selected_attributes"
              placeholder="PLP"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in plpFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for join team  -->
          <el-col :md="8"
            ><el-select
              v-model="filter.team_member"
              placeholder="Teams"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in joinTeamsFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for decode tasks -->
          <el-col :md="8"
            ><el-select
              v-model="filter.tasks"
              placeholder="Decoded School Tasks"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in decodeTasksFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for decode work tasks -->
          <el-col :md="8"
            ><el-select
              v-model="filter.work_tasks"
              placeholder="Decoded Work Tasks"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in decodeWorkTasksFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <!-- filter for decode career tasks -->
          <el-col :md="8"
            ><el-select
              v-model="filter.career_tasks"
              placeholder="Decoded Career Tasks"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="item in decodeCareerTasksFilters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
        </el-row>
      </template>
      <template #columns>
        <el-table-column
          prop="first_name"
          :label="`${localization('First Name')}`"
          min-width="15"
        >
        </el-table-column>
        <el-table-column
          prop="last_name"
          :label="`${localization('Last Name')}`"
          min-width="15"
        >
        </el-table-column>

        <el-table-column prop="___" label="" min-width="15">
          <template slot-scope="scope">
            <!-- <div> -->
            <div
              class="tttttt d-flex align-items-center flex-wrap"
              style="gap:3px"
            >
              <!-- View Profile  -->
              <a
                v-if="hasPermission('show_users')"
                v-tooltip.top-center="`${localization('View profile')}`"
                class=""
                @click="$router.push(`user-profile/${scope.row.id}`)"
              >
                <i class="fa fa-user" style="font-size: 17px"></i>
              </a>
              <!-- Delete or Reactivate User  -->
              <a
                v-if="hasPermission('edit_users')"
                v-tooltip.top-center="
                  scope.row.removed == 0
                    ? `${localization('Delete user')}`
                    : `${localization('Active user')}`
                "
                @click="remove(scope.row.id, scope.row.removed)"
                :class="
                  scope.row.removed == 0
                    ? `btn-danger btn-link `
                    : `btn-success btn-link `
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
                <i v-else class="fa fa-check"></i>
              </a>
              <a
                v-if="hasPermission('manage_institutions')"
                v-tooltip.top-center="`Remove user from institution`"
                @click="removeUserFromInstitutions(scope.row.id)"
                :class="
                  scope.row.removed == 0
                    ? ` btn-danger btn-link btn-xs`
                    : ` btn-success btn-link btn-xs`
                "
              >
                <i v-if="scope.row.removed == 0" class="fa fa-trash"></i>
              </a>
            </div>
            <!-- </div> -->
          </template>
        </el-table-column>
      </template>
      
    </CustomTable>
    <!-- //? Push task dialog  -->
    <el-dialog
      :title="`${localization('Push Task')}`"
      :visible.sync="task_dialog"
      top="4vh"
      width="50%"
      class="add_category_dialog_user"
    >
      <el-form :model="task" ref="pushTaskFormRef" :rules="pushTaskRules">
        <el-row :gutter="10"> </el-row>
        <el-form-item :label="`${localization('Name')}`" prop="name">
          <el-input
            v-model="task.name"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="`${localization('Description')}`"
          prop="description"
        >
          <el-input
            v-model="task.description"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item :label="`${localization('Topic')}`" prop="topic_id">
          <el-select
            v-model="task.topic_id"
            clearable
            :placeholder="`${localization('Topic')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in $store.getters['topics/topics']"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer dialog_footer">
        <el-button
          class="text-center text-capitalize"
          @click="task_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          class="text-center text-capitalize"
          type="primary"
          @click="PushTask"
          >{{ localization("Push Task") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- // Push Career Task  -->
    <el-dialog
      title="Push Career Task"
      :visible.sync="career_task_dialog"
      top="4vh"
      width="50%"
      class="add_category_dialog_user"
    >
      <el-form
        :model="careerTask"
        ref="pushCareerTaskFormRef"
        :rules="pushCareerTaskRules"
      >
        <el-row :gutter="10"> </el-row>
        <el-form-item :label="`${localization('Name')}`" prop="name">
          <el-input
            v-model="careerTask.name"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="`${localization('Description')}`"
          prop="description"
        >
          <el-input
            v-model="careerTask.description"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item :label="`${localization('Topic')}`" prop="topic_id">
          <el-select
            v-model="careerTask.topic_id"
            clearable
            :placeholder="`${localization('Topic')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in careerMatchTasksTopics"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer dialog_footer">
        <el-button
          class="text-center text-capitalize"
          @click="career_task_dialog = false"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          class="text-center text-capitalize"
          type="primary"
          @click="pushCareerTask"
          >{{ localization("Push Task") }}</el-button
        >
      </span>
    </el-dialog>

    <!-- Edit User Form  -->
    <el-dialog
      title="Edit User"
      :visible.sync="edit_user_dialog"
      top="4vh"
      width="50%"
    >
      <el-row>
        <el-col :span="24">
          <!-- <el-card style="width: 100%"> -->
          <!-- Form Header  -->
          <template #header>
            <div class="font-weight-light">Complete your profile</div>
          </template>

          <el-tabs success v-loading="Object.keys(this.user).length == 0">
            <!-- Personal Info Form  -->
            <el-tab-pane label="Personal Info">
              <span slot="label"
                ><i class="el-icon-user"></i> Personal Info</span
              >
              <el-form
                ref="ruleForm"
                :model="user"
                :rules="rules"
                label-position="top"
              >
                <el-row class="pt-2" :gutter="20">
                  <!-- First Name  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="First Name"
                      label-width="20%"
                      prop="first_name"
                    >
                      <el-input
                        placeholder="First Name"
                        v-model="user.first_name"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- Last Name  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="Last Name"
                      label-width="20%"
                      prop="last_name"
                    >
                      <el-input
                        v-model="user.last_name"
                        class="purple-input"
                        placeholder="Last Name"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- Email  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item label="Email" label-width="20%" prop="email">
                      <el-input
                        style="width: 100%"
                        placehoder="Email"
                        v-model="user.email"
                        class="purple-input"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- Age  -->
                  <el-col :sm="6" class="my-2">
                    <el-form-item
                      label="Age"
                      label-width="20%"
                      prop="age_group_id"
                    >
                      <el-select
                        v-model="user.age_group_id"
                        placeholder=" Age"
                        class="select-default"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in ageList"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- Language  -->
                  <el-col :sm="6" class="my-2">
                    <el-form-item
                      label="Language"
                      label-width="20%"
                      prop="language"
                    >
                      <el-select
                        v-model="user.language"
                        placeholder=" Language"
                        class="select-default"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in $store.getters['language/languages']"
                          :value="item.id"
                          :label="item.title"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- Postal Code  -->
                  <el-col :sm="12" class="my-2">
                    <!-- <el-form-item
                    label="ZIP / Postal Code"
                    label-width="20%"
                    prop="zip_code"
                  >
                    <el-input
                      v-model="user.zip_code"
                      placeholder="ZIP / Postal Code"
                    ></el-input>
                  </el-form-item> -->
                    <el-form-item
                      label="ZIP / Postal Code"
                      label-width="20%"
                      prop="zip_code"
                    >
                      <el-input
                        v-model="user.zip_code"
                        placeholder="ZIP / Postal Code"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- Country  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      label="Country"
                      label-width="20%"
                      prop="country"
                    >
                      <el-select
                        v-model="user.country"
                        filterable
                        placeholder=" Country"
                        class="select-default"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in countries"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col class="text-right">
                    <l-button @click="save(user.id)" type="info" class="mr-0">
                      Change My Info
                    </l-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>

            <!-- Institution Info Form  -->
            <el-tab-pane
              label="Institution Info"
              v-loading="isInstitutionInfoLoading"
            >
              <span slot="label"
                ><i class="el-icon-office-building"></i> Institution Info</span
              >
              <el-card>
                <template #header>
                  <div class="mb-2">My Institution</div>
                </template>
                <span class="d-block mb-2"
                  ><span>Institution Name:</span>
                  {{ user.organization_name }}</span
                >
                <span class="d-block mb-2"
                  ><span>Institution Email:</span>
                  {{ user.organization_email }}</span
                >
                <span class="d-block mb-2">
                  <span>Institution Contact:</span>
                  {{ user.organization_contact_name }}</span
                >
                <el-col :span="24" class="text-right">
                  <el-button
                    v-if="!user.organization_id"
                    @click="show_institution_promo_dialog = true"
                    type="success"
                    class="mb-3"
                  >
                    Join Institution
                  </el-button>
                  <el-button
                    v-if="user.organization_id"
                    @click="leaveOrganization"
                    type="danger"
                    class="mb-3"
                  >
                    Leave Institution
                  </el-button>
                </el-col>
              </el-card>
              <!-- Join new Institution Form  -->
              <el-dialog
                title="Enter Institution Promo Code"
                :visible.sync="show_institution_promo_dialog"
                append-to-body
              >
                <el-form
                  :model="promoForm"
                  :rules="promoRules"
                  ref="promoRef"
                  v-loading="isInstitutionInfoLoading"
                >
                  <el-form-item
                    label="Promo Code"
                    label-width="120px"
                    prop="new_institution_promo"
                  >
                    <el-input
                      v-model="promoForm.new_institution_promo.promo_code"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="show_institution_promo_dialog = false"
                    >Cancel</el-button
                  >
                  <el-button type="primary" @click="submitPromoCode"
                    >Confirm</el-button
                  >
                </span>
              </el-dialog>
            </el-tab-pane>
            <!-- Reset Password Form  -->
            <el-tab-pane
              label="Resset Password"
              v-loading="isResetPasswordLoading"
            >
              <span slot="label"
                ><i class="el-icon-lock"></i> Change Password</span
              >
              <el-card>
                <template #header>
                  <div class="mb-2">Change User Password</div>
                </template>
                <el-form
                  v-model="resetPassword"
                  :model="resetPassword"
                  ref="resetPassRef"
                  :rules="resetPassRules"
                  style="width: 95%"
                >
                  <div>
                    <el-form-item
                      label="Password"
                      prop="password"
                      style="width: 100%"
                      class="position-relative"
                    >
                      <!-- Show Password Icon  -->
                      <i
                        v-if="passwordType == 'password'"
                        class="
                          fa fa-eye
                          position-absolute
                          togglePassword
                          text-secondary
                        "
                        @click="toggleShowPassword"
                        v-tooltip.top-center="'Show Password'"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="passwordType == 'text'"
                        class="
                          fa fa-eye-slash
                          position-absolute
                          togglePassword
                          text-secondary
                        "
                        @click="toggleShowPassword"
                        v-tooltip.top-center="'Hide Password'"
                        aria-hidden="true"
                      ></i>
                      <el-input
                        :type="passwordType"
                        v-model="resetPassword.password"
                        placeholder="Please enter Password"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Confirm Password"
                      prop="confirmPassword"
                      style="width: 100%"
                      class="position-relative"
                    >
                      <i
                        v-if="confirmPasswordType == 'password'"
                        class="
                          fa fa-eye
                          position-absolute
                          togglePassword
                          text-secondary
                        "
                        @click="toggleShowConfirmPassword"
                        v-tooltip.top-center="'Show Password'"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="confirmPasswordType == 'text'"
                        class="
                          fa fa-eye-slash
                          position-absolute
                          togglePassword
                          text-secondary
                        "
                        @click="toggleShowConfirmPassword"
                        v-tooltip.top-center="'Hide Password'"
                        aria-hidden="true"
                      ></i>
                      <el-input
                        :type="confirmPasswordType"
                        v-model="resetPassword.confirmPassword"
                        placeholder="Please enter Password"
                        style="width: 100%"
                      ></el-input>
                    </el-form-item>
                  </div>

                  <div style="width: 100%" class="mb-3 mt-3">
                    <l-button
                      @click="submit"
                      type="info"
                      class="pl-5 pr-5 m-auto d-block"
                    >
                      Submit
                    </l-button>
                  </div>
                </el-form>
              </el-card>
            </el-tab-pane>
          </el-tabs>
          <!-- </el-card> -->
        </el-col>
      </el-row>
    </el-dialog>

    <!-- //? Start Add Category Modal  -->
    <el-dialog
      :title="`${localization('Add Category')}`"
      :visible.sync="category_modal"
      width="50%"
      top="4vh"
      class="add_category_dialog_user"
    >
      <el-select
        v-model="totalSelectedCategories"
        :placeholder="`${localization('Please select category')}`"
        style="width: 100%"
        multiple
      >
        <el-option
          v-for="item in $store.getters['user/categories']"
          :key="item.id"
          :label="item.category"
          :value="item.id"
        ></el-option>
      </el-select>
      <!-- Start Modal Footer Save && Cancel  -->
      <div
        slot="footer"
        class="
            dialog-footer
            dialog_footer
          "
      >
        <el-button
          @click="closeCategoryModal"
          class="text-center text-capitalize"
          >{{ localization("Cancel") }}</el-button
        >

        <el-button
          @click="addCategory"
          type="primary"
          class="
              text-center
              text-capitalize
            "
          >{{ localization("Save") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- //? end Add Category Modal  -->

    <!-- Start add users from institution to another institution  -->
    <el-dialog
      :title="`Change Organization`"
      :visible.sync="institution_modal"
      width="50%"
      top="4vh"
      class="add_category_dialog_user"
    >
      <el-select
        v-model="selectedInst.organization_id"
        :placeholder="`Please select organizations`"
        style="width: 100%"
      >
        <el-option
          v-for="item in orgsInstitutionsData"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <!-- Start Modal Footer Save && Cancel  -->
      <div
        slot="footer"
        class="
            dialog-footer
            dialog_footer
          "
      >
        <el-button
          @click="closeOrganizationInstitutions"
          class="text-center text-capitalize"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          @click="changeOrganizationInstitutions"
          type="primary"
          class="
              text-center
              text-capitalize
            "
          :disabled="selectedInst.organization_id == null"
          >{{ localization("Save") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- end add users from institution to another institution  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
import LAlert from "src/components/Alert";
export default {
  name: "index",
  components: { ServerTable, LAlert },
  data() {
    return {
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selectedForInstitutions: [],
      totalSelectedCategories: [],
      category: "",
      selected_category_members: [],
      allCategories: [],
      category_modal: false,
      value: {},
      // ========
      filter: {},
      task: {},
      task_dialog: false,
      edit_user_dialog: false,
      show_tasks_dialog: false,
      show_user_data_dialog: false,
      selected: [],
      types: ["individual", "student", "institution"],
      categories: ["CLASS AA", "CLASS B"],
      user: {},
      // new filters for
      // 1- users did not finish LCI
      // 2- users did not finish PLP
      // 3- users did not decode tasks
      // 3- users did not join teams
      // for lci -- 1- users did not finish LCI
      lciFilters: [
        {
          id: "0",
          name: "Did not finish LCI"
        },
        {
          id: "1",
          name: "Finished LCI"
        }
      ],
      // for lci -- 2- users did not finish PLP
      plpFilters: [
        {
          id: "0",
          name: "Did not finish PLP"
        },
        {
          id: "1",
          name: "Finished PLP"
        }
      ],
      // users did not join teams
      joinTeamsFilters: [
        {
          id: "0",
          name: "Did not join teams"
        },
        {
          id: "1",
          name: "Joined teams"
        }
      ],
      // users did not decode tasks -- 3 types - tasks
      decodeTasksFilters: [
        {
          id: "0",
          name: "Did not decode school tasks"
        },
        {
          id: "1",
          name: "Decoded school tasks"
        }
      ],
      // users did not decode tasks -- 3 types - work_tasks
      decodeWorkTasksFilters: [
        {
          id: "0",
          name: "Did not decode work tasks"
        },
        {
          id: "1",
          name: "Decoded work tasks"
        }
      ],
      // users did not decode tasks -- 3 types - career_tasks
      decodeCareerTasksFilters: [
        {
          id: "0",
          name: "Did not decode career tasks"
        },
        {
          id: "1",
          name: "Decoded career tasks"
        }
      ],
      rules: {
        first_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        zip_code: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        age_group_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        country: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        card_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        card_number: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        exp_time: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        cvc_code: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
            min: 3
          }
        ]
      },
      pushTaskRules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      credit: {
        name: null,
        number: null,

        expiration: null,
        cvc: null,
        zipcode: null,
        amount: null
      },
      email: {
        confirmemail: null
      },
      ageList: [
        {
          value: 1,
          label: `${
            this.localization("Small People") != false
              ? this.localization("Small People")
              : "6-12 years old"
          }`
        },
        {
          value: 2,
          label: `${
            this.localization("Young People") != false
              ? this.localization("Young People")
              : "13 -21 years old"
          }`
        },
        {
          value: 3,
          label: `${
            this.localization("Old People") != false
              ? this.localization("Old People")
              : "22 or older"
          }`
        },
        {
          value: 4,
          label: `${
            this.localization("ABE") != false ? this.localization("ABE") : "ABE"
          }`
        }
      ],
      category: [],

      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia ",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory ",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands ",
        "Central African Republic ",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Islands ",
        "Colombia",
        "Comoros ",
        "Congo ",
        "Congo ",
        "Cook Islands ",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic ",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands  [Malvinas]",
        "Faroe Islands ",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories ",
        "Gabon",
        "Gambia ",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea",
        "Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands ",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands ",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger ",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands ",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan ",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands ",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates ",
        "United Kingdom of Great Britain and Northern Ireland ",
        "United States Minor Outlying Islands ",
        "United States of America ",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
      ],
      isInstitutionInfoLoading: false,
      show_institution_promo_dialog: false,
      promoForm: {
        new_institution_promo: {
          promo_code: null
        }
      },
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        }
      },
      promoRules: {
        new_institution_promo: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      joinFullORgError: "",
      isResetPasswordLoading: false,
      passwordType: "password",
      confirmPasswordType: "password",
      resetPassword: {},
      resetPassRules: {
        password: [
          {
            required: true,
            message: "Please input password",
            trigger: "blur"
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please reenter password",
            trigger: "blur"
          }
        ]
      },
      selectedInst: {
        users: [],
        organization_id: null
      },
      institution_modal: false,
      career_task_dialog: false,
      pushCareerTaskRules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      careerTask: {}
    };
  },
  mounted() {
    // Get sorted Institutions for filtering
    this.$store.dispatch("organization/orgs");
    // get langauges available languages for user language set
    this.$store.dispatch("language/getLanguages");
    if (this.$store.getters["filter"])
      this.filter = this.$store.getters["filter"];
  },
  computed: {
    // auto renew auto checked for US users
    autoRenewCheck() {
      return this.user.country === "United States";
    },
    // add users from institution to another institution
    organizationsInstitutions() {
      return this.$store.getters["organization/organizations"];
    },
    orgsInstitutionsData() {
      return this.$store.getters["organization/getOrgsData"];
    },
    careerMatchTasksTopics() {
      return this.$store.getters["user/getCareerMatchTasksTopics"];
    },
    institutionsUsers() {
      return this.$store.getters["user/getInstitutionsUsers"];
    }
  },

  methods: {
    Refresh(query) {
      this.$store.dispatch("user/users", { query: query });
      this.$store.dispatch("topics/GetTopics", { query: null });
      // this.$store.dispatch("organization/organizations", { query: query });
      this.$store.dispatch("user/handlerCareerMatchTopics");
    },
    changeOrganizationInstitutions() {
      this.$store
        .dispatch("organization/changeOrganization", this.selectedInst)
        .then(() => {
          this.institution_modal = false;
          this.selected = [];
          this.selectedInst.users = [];
          this.selectedInst.organization_id = null;
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
          this.Refresh();
        });
    },
    closeOrganizationInstitutions() {
      this.institution_modal = false;
    },
    save(id) {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/save", { id: `/${id}`, query: this.user })
            .then(_ => {
              this.Refresh({});
              this.edit_user_dialog = false;
            });
        } else {
          return false;
        }
      });
    },
    // Print
    printInstitutionsUsers() {
      let payload = {
        users_ids: this.selected
      };
      this.$store
        .dispatch("user/handlePrintInstitutionsUsers", payload)
        .then(() => {
          window.print();
          this.Refresh();
        });
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
      this.selectedInst.users = Array.from(selections, el => el.id);
    },
    Export(query) {
      this.$store.dispatch("user/export", { query: query });
    },
    remove(id, removed) {
      Swal.fire({
        title: "",
        text:
          removed == 0
            ? `${this.localization("Are You Sure")}`
            : `${this.localization("Are You Sure")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText:
          removed == 0
            ? `${this.localization("Delete")}`
            : `${this.localization("Activate")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("user/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: removed == 0 ? `` : "",
              text: `${this.localization("Done")}`,
              icon: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
              confirmButtonText: `${this.localization("Ok")}`
            });
          });
        }
      });
    },
    // deleteUserFromInstitutions
    //// Start Remove User From Institutions
    removeUserFromInstitutions(id) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/deleteUserFromInstitutions", id)
            .then(_ => {
              this.Refresh(null);
              Swal.fire({
                text: `${this.localization("Deleted")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                confirmButtonText: `${this.localization("Ok")}`
              });
            });
        }
      });
    },
    //// End Remove User From Institutions
    getAgeGroup(id) {
      switch (id) {
        case 1:
          return "6-12 years";
        case 2:
          return "13 -21 years";
        case 3:
          return "22 or older";
        case 4:
          return "ABE";
        default:
          return "-";
      }
    },

    PushTask() {
      this.$refs["pushTaskFormRef"].validate(valid => {
        if (valid) {
          this.task.users_id = this.selected;
          this.task.description = this.task.description.replace(/\s/g, "_");
          this.$store
            .dispatch("user/push_task", { query: this.task })
            .then(_ => {
              this.task_dialog = false;
              this.selected = [];
              this.task.name = "";
              this.task.description = "";
              this.task.topic_id = "";
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
              this.Refresh();
            });
        } else {
          return false;
        }
      });
    },
    // push Career Task
    pushCareerTask() {
      this.$refs["pushCareerTaskFormRef"].validate(valid => {
        if (valid) {
          this.careerTask.users_id = this.selected;
          this.careerTask.description = this.careerTask.description.replace(
            /\s/g,
            "_"
          );
          this.$store
            .dispatch("user/handlerPushCareerTask", { query: this.careerTask })
            .then(_ => {
              this.career_task_dialog = false;
              this.selected = [];
              this.careerTask.name = "";
              this.careerTask.description = "";
              this.careerTask.topic_id = "";
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
              this.Refresh();
            });
        } else {
          return false;
        }
      });
    },

    // Institutions Methods
    leaveOrganization() {
      // set laoding to true to shpw loading while making request
      this.isInstitutionInfoLoading = true;

      this.$store
        .dispatch("user/leaveOrganization", { id: this.user.id })
        .then(_ => {
          this.$store.dispatch("user/user", this.user.id).then(_ => {
            // refresh after leaving institution
            this.user = this.$store.state.user.alluserData;
            this.org = this.$store.getters["user/orgs"];
            // remove loading
            this.isInstitutionInfoLoading = false;
            // update table data
            this.Refresh({});
          });
        });
    },

    submitPromoCode() {
      this.$refs["promoRef"].validate(valid => {
        if (valid) {
          this.isInstitutionInfoLoading = true;
          this.$store
            .dispatch("user/joinOrganization", {
              promoCode: this.promoForm.new_institution_promo,
              id: this.user.id
            })
            .then(_ => {
              //  If Organiations is full, server will retur an error messgae with the response
              this.joinFullORgError = this.$store.getters[
                "user/join_full_org_error"
              ]; // if organization is full
              if (this.joinFullORgError.length > 0) {
                Swal.fire({
                  title: "Error!",
                  text: this.joinFullORgError,
                  icon: "error",
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000
                }).then(() => {
                  // close promocode dialog
                  this.show_institution_promo_dialog = false;
                  // remove loading
                  this.isInstitutionInfoLoading = false;
                  // empty promo code input
                  this.promoForm.new_institution_promo = {};
                });
              } else {
                // if organization is not full
                // update data in panels
                this.$store.dispatch("user/user", this.user.id).then(() => {
                  // refresh after Joining institution
                  this.user = this.$store.state.user.alluserData;
                  this.org = this.$store.getters["user/orgs"];
                  // close promocode dialog
                  this.show_institution_promo_dialog = false;
                  // remove loading
                  this.isInstitutionInfoLoading = false;
                  // update table data
                  this.Refresh({});
                  // empty promo code input
                  this.promoForm.new_institution_promo = {};
                });
              }
            });
        } else {
          return false;
        }
      });
    },

    // reset password methods
    toggleShowPassword() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    toggleShowConfirmPassword() {
      this.confirmPasswordType =
        this.confirmPasswordType == "password" ? "text" : "password";
    },
    submit() {
      this.$refs["resetPassRef"].validate(valid => {
        if (valid) {
          this.isResetPasswordLoading = true;
          //  If passwords does not match
          if (
            this.resetPassword.password !== this.resetPassword.confirmPassword
          ) {
            Swal.fire({
              icon: "error",
              text: "Passwords does not match",
              confirmButtonClass: "btn btn-info btn-fill",
              showConfirmButton: false,
              showCloseButton: true
            });
          } else {
            // call reset password on store
            this.$store
              .dispatch("user/changePasswordByAdmin", {
                id: this.user.id,
                newPasswordData: {
                  password: this.resetPassword.password,
                  confirm_password: this.resetPassword.confirmPassword
                }
              })
              .then(() => {
                Swal.fire({
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  confirmButtonClass: "btn btn-info btn-fill",
                  showConfirmButton: false,
                  showCloseButton: true
                });
                this.isResetPasswordLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    openCategoryModal(items) {
      this.category_modal = false;
      this.allCategories = items;
    },
    closeCategoryModal() {
      this.category_modal = !this.category_modal;
      this.selected = [];
      this.value = "";
    },
    addCategory() {
      // this.$refs["add_category_form"].validate(valid => {
      //   if (valid) {
      this.$store
        .dispatch("user/addCategory", {
          categories: this.totalSelectedCategories,
          users: this.selected
        })
        .then(() => {
          this.category_modal = false;
          this.selected = [];
          this.totalSelectedCategories = [];
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
          this.value = "";
          this.totalSelectedCategories = [];
          this.Refresh();
        });
      // }
      // });
    },
    allUsersInTable() {
      this.$store.dispatch("user/users", { query: query });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    getHint(key, type) {
      switch (type) {
        // case "Avoid":
        case "Avoid":
          if (key == "Sequence") {
            return `${this.localization("Since my scale score for Sequence is between 7-17, I avoid Sequence")}`;
          } else if (key == "Precision") {
            return `${this.localization("Since my scale score for Precision is between 7-17, I avoid Precision")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("Since my scale score for Technical Reasoning is between 7-17, I avoid Technical Reasoning")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Since my scale score for Confluence is between 7-17, I avoid Confluence")}`;
          }
        // return `${this.localization("Scale Score")} ${this.localization(
        //   key
        // )} ${this.localization("First Score Degree")} ${this.localization(
        //   key
        // )}`;
        case "As Needed":
          // case "As Needed":
          if (key == "Sequence") {
            return `${this.localization("Since my scale score for Sequence is between 18-24, I use Sequence as needed.")}`;
          } else if (key == "Precision") {
            return `${this.localization("Since my scale score for Precision is between 18-24, I use Precision as needed.")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("Since my scale score for Technical Reasoning is between 18-24, I use Technical Reasoning as needed.")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Since my scale score for Confluence is between 18-24, I use Confluence as needed.")}`;
          }
        // return `${this.localization("Scale Score")} ${this.localization(
        //   key
        // )} ${this.localization("Second Score Degree")} ${this.localization(
        //   key
        // )} ${this.localization("as needed")}`;
        // case `${this.localization("Use First")}`:
        case `Use First`:
          if (key == "Sequence") {
            return `${this.localization("Since my scale score for Sequence is between 25-35, I use Sequence first.")}`;
          } else if (key == "Precision") {
            return `${this.localization("Since my scale score for Precision is between 25-35, I use Precision first.")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("Since my scale score for Technical Reasoning is between 25-35, I use Technical Reasoning first.")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Since my scale score for Confluence is between 25-35, I use Confluence first.")}`;
          }
        // return `${this.localization("Scale Score")} ${this.localization(
        //   key
        // )} ${this.localization("Third Score Degree")} ${this.localization(
        //   key
        // )} ${this.localization("first")}`;
        case "Sequence":
          // case `${this.localization("Sequence")}`:
          return `${this.localization("Hey, you have enough Sequence to complete this task. But you might want to check out these strategies to keep your Sequence pattern from taking over.")}`;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.togglePassword {
  z-index: 5;
  right: 12px;
  bottom: 5%;
  transform: translateY(-50%);
  font-size: 1.1rem;
  cursor: pointer;
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
// Style For Table
::v-deep .el-table .cell {
  word-break: break-word !important;
  padding: 0;
  font-size: 13px !important;
}
::v-deep {
  .el-table td,
  .el-table th.is-leaf {
    text-align: center;
  }
}
::v-deep {
  thead tr th .cell {
    font-size: 13px !important;
  }
}
.tttttt {
}
</style>

<style lang="scss">
.add_category_dialog_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.main_mobile_wrapper_users {
  .el-table .cell {
    word-break: break-word !important;
  }
  .el-table__body > tbody > tr {
    td {
      @media (min-width: 320px) and (max-width: 480px) {
        padding: 0 !important;
      }
      .cell {
        @media (min-width: 320px) and (max-width: 480px) {
          padding: 0 !important;
          width: fit-content;
          word-break: break-word;
          white-space: normal;
          font-size: 12px;
        }
      }
    }
  }
}
.main_users_wrapping_container {
  .main_big_screen_wrapper_users {
    .el-table thead tr th:first-child,
    .el-table tbody tr td:first-child {
      padding-left: 0;
      width: 20px;
      // background: #f00;
    }
    .el-table__body > tbody > tr {
      td {
        padding: 12px 0 !important;

        .cell {
          padding: 0 5px;
          // @media (min-width: 320px) and (max-width: 480px) {
          //   padding: 0 !important;
          //   width: fit-content;
          //   word-break: break-word;
          //   white-space: normal;
          //   font-size: 12px;
          // }
        }
      }
    }
  }
}

// Start the_main_container_for_print_all_pdf
.the_main_container_for_print_all_pdf {
  display: none;
  @media print {
    display: block !important;
  }
  .main_container {
    .main_auth_user_info {
      text-align: center;
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .n_name {
        font-size: 24px;
        font-weight: bold;
        text-transform: capitalize;
      }
      .n_email {
        font-size: 22px;
      }
      .classification_classification {
        padding: 0 36px;
        min-width: 180px;
        border-radius: 12px;
        background-color: #00563f;
        height: 45px;
        display: flex;
        align-items: center;
        width: fit-content;
        color: #fff;
        margin: 10px auto;
      }
      // Below Score Cards
    }
    .the_patterns_with_new_theme {
      position: relative;
      .new_theme_pattern_content {
        // display: none;
        display: block;
        @media print {
          display: block !important;
        }
        padding: 0 10px;
        .main_content_container {
          border-left: 2px solid;
          border-right: 2px solid;
          border-bottom: 2px solid;
          text-align: center;
          padding: 10px 5px;
          &.with_sequence {
            border-color: #39c;
          }
          &.with_precision {
            border-color: #3c3;
          }
          &.with_technical_reasoning {
            border-color: #f93;
          }
          &.with_confluence {
            border-color: #c36;
          }
          .ti {
            // color: #555;
            font-size: 15px;
          }
          .te {
            color: #777;
            font-size: 13px;
          }
        }
      }
    }
    .wrapper_for_use_first {
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .main_co {
        background-color: #eee;
        display: flex;
        justify-content: center;
        gap: 10px;
        color: #7d7979;
        padding: 15px;
        .a_one {
          &.with_title {
            color: #555;
          }
        }
        .a_two {
          border-left: 3px solid #777;
          border-right: 3px solid #777;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .main_collapse_title_wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding: 20px 10px;
      .every_collapse_title {
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: 18px;
        }
        @media (min-width: 320px) and (max-width: 350px) {
          font-size: 11px;
        }
      }
      .every_collapse_button {
        color: #fff;
        width: 50px;
        height: 50px;
        @media (min-width: 320px) and (max-width: 480px) {
          width: 36px;
          height: 36px;
        }
      }
      &.is_rtl {
        flex-direction: row-reverse;
        padding-right: 10px;
      }
    }

    .set_bg_white_for_share {
      background: #fff;
    }
    .alert_for_collapse {
      font-size: 1.2rem;
      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 1rem;
      }
      /* Define custom class for page break */
      .print-page-break {
        page-break-inside: avoid;
      }
      .print-page-break_2 {
        page-break-inside: avoid;
      }
    }
    .el-collapse {
      background: #fff;
    }
    .wrapper_check_speech {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .icons_style {
      // background-color: #eee;
      // box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      font-size: 14px;
      width: 30px;
      height: 30px;
      // box-shadow: $simple-shadow;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      cursor: pointer;
      color: #fff;
      transition: 0.3s;
      &.speech {
        &:hover {
          background-color: #11bfe3;
          color: #fff;
        }
      }
      &.delete {
        &:hover {
          background-color: #fb404b;
          color: #fff;
        }
      }
    }
  }
  .handler_strategy_style {
    font-size: 1.2rem;
    border-radius: 4px;
    color: #ffffff;
    padding: 10px 15px;
    line-height: 1.7;

    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 1rem;
    }
  }
  .letter {
    font-size: 40px;
  }
  .span_attr_type {
    font-size: 20px;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
  .hide_me_printable {
    display: none !important;
  }
  .looping_tasks_here {
    page-break-inside: avoid;
  }
  .print-page-break {
    page-break-after: always;
  }
  .print-page-break_2 {
    page-break-after: always;
  }
}
</style>
